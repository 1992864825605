<template>
  <div>
    <UniversalTable
      class="table"
      :head-rows="headers"
    >
      <template v-slot:tr>
        <tr
          v-for="(item) in items"
          :key="item.id"
          class="tr"
        >
          <td class="py-3 text-start">
            <span>
              {{ item.title }}
            </span>
          </td>
          <td class="text-start">
            <span>
              {{ String(item.keywords).replace(/,/g, ', ') }}
            </span>
          </td>
          <td class="text-start">
            <span>
              {{ String(item.keywordsAnti).replace(/,/g, ', ') }}
            </span>
          </td>
          <td class="text-center">
            <span>
              {{ Math.round(item.coeffRest * 100) / 100 }} %
            </span>
          </td>
          <td class="text-center">
            <span>
              {{ Math.round(item.coeffFood * 100) / 100 }} %
            </span>
          </td>
          <td fluid>
            <div class="d-flex flex-column py-3 align-center">
              <VBtn
                :class="['default-btn white--text mt-1', { 'loading' : item.loading}]"
                color="#4caf50"
                :disabled="item.loading"
                rounded
                @click="tagLabel(item)"
              >
                {{ item.loading ? 'Присваиваем метку...' : 'Присвоить метку' }}
              </VBtn>
              <span v-if="item.lastSyncDate != null" class="update-time mt-2">
                Изменено {{ item.lastSyncDate }}
              </span>
            </div>
          </td>
          <td>
            <div class="d-flex align-start pl-0 pr-0">
              <VBtn
                class="btn-delete btn-icon"
                color="#D0021B"
                fab
                @click="deleteLabel(item)"
              >
                <VIcon color="white" small>
                  mdi-delete
                </VIcon>
              </VBtn>
              <VBtn
                class="ml-3 btn-icon"
                fab
                icon
                @click="editLabel(item)"
              >
                <VIcon color="#1E262D">
                  mdi-chevron-right
                </VIcon>
              </VBtn>
            </div>
          </td>
        </tr>
      </template>
    </UniversalTable>
  </div>
</template>

<script>
import UniversalTable from '@/components/UniversalTable.vue';
import { FoodLabelsHeader } from '@/conditions/tablesHeaders';

export default {
  props: {
    items: {
      type: Array,
      required: false,
    },
  },
  components: {
    UniversalTable,
  },
  emits: ['edit', 'delete', 'update'],
  data() {
    return {
      headers: FoodLabelsHeader,
    };
  },
  methods: {
    editLabel(item) {
      this.$emit('edit', item);
    },
    deleteLabel(item) {
      this.$emit('delete', item);
    },
    tagLabel(item) {
      this.$emit('update', item);
    },
  },
};
</script>
<style lang="scss" scoped>
@import "src/assets/scss/page.scss";

.tr>* {
  font-size: 14px !important;
}

::v-deep {
  .table {
    .v-data-table__wrapper {
      box-shadow: 0 0 5px 1px rgb(0 0 0 / 20%);
    }
    thead {
      background-color: #616262;
      span {
        color: white;
        font-size: 14px;
      }
    }
    .default-btn {
      height: 21px;
    }
    .loading {
      background-color: #C7CACE!important;
      color: #FFFFFF!important;
    }
  }
  .btn-icon {
    height: 24px !important;
    width: 24px !important;
  }
  .update-time {
    font-weight: 400;
    font-size: 10px;
    line-height: 12px;
    color: #3D3D3D;
  }
}
</style>
