<template>
  <VSimpleTable class="elevation-0">
    <thead>
      <tr
        v-if="headRows.length"
        class="titles-thead"
      >
        <th
          v-for="(row, index) in headRows"
          :key="row.key || index"
          :class="row.classList"
          :style="row.styles || {}"
        >
          <span>{{ row.text }}</span>
        </th>
      </tr>
      <slot name="th" />
      <tr class="search">
        <slot name="search" />
      </tr>
    </thead>
    <tbody>
      <slot name="tr" />
    </tbody>
  </VSimpleTable>
</template>

<script>
export default {
  props: {
    headRows: {
      type: Array,
      default: () => [],
    },
  },
};
</script>

<style lang="scss" scoped>
.search {
  background-color: #3E3E3E;
}
</style>
