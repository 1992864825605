<template>
  <VBtn
    class="elevation-0"
    :color="color"
    :disabled="disabled"
    :elevation="elevation"
    fab
    :max-height="maxHeight"
    :max-width="maxWidth"
    @click="click"
  >
    <VImg
      v-if="src"
      contain
      height="20px"
      :src="src"
      width="20px"
    />
    <VIcon v-if="icon" :color="iconColor">
      {{ icon }}
    </VIcon>
  </VBtn>
</template>

<script>
import trashIcon from '@/assets/icons/trash.svg';

export default {
  props: {
    color: {
      type: String,
      default: '#EB5757',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: String,
      default: null,
    },
    iconColor: {
      type: String,
      default: 'white',
    },
    src: {
      type: String,
      default: trashIcon,
    },
    maxHeight: {
      type: [String, Number],
      default: 35,
    },
    maxWidth: {
      type: [String, Number],
      default: 35,
    },
    elevation: {
      type: [Number, String],
      default: 2,
    },
  },
  emits: ['click'],
  data() {
    return {
      trashIcon,
    };
  },
  methods: {
    click() {
      this.$emit('click');
    },
  },
};
</script>
