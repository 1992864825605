<template>
  <VDialog
    class="br-12"
    max-width="400"
    :value="dialog"
    @click:outside="close"
  >
    <VCard class="pa-6">
      <div class="d-flex justify-space-between">
        <div v-if="title !=null" class="d-flex align-center">
          <p class="label-title">
            Удалить {{ title }}?
          </p>
        </div>
        <RoundButton
          color="#E5E5E5"
          elevation="0"
          icon="mdi-close"
          icon-color="#565656"
          :max-height="30"
          :max-width="30"
          src=""
          @click="close"
        />
      </div>
      <VContainer class="">
        <p class="label-text">
          {{ textMain }}
        </p>
        <VRow class="my-3" justify="space-between">
          <VBtn
            class="default-btn cancel-btn elevation-0 mr-3 white--text"
            color="#1E262D"
            x-large
            @click="close"
          >
            {{ textCancel }}
          </VBtn>
          <VBtn
            class="default-btn save-changes-btn elevation-0 white--text"
            color="#E43B35"
            x-large
            @click="remove"
          >
            {{ textConfirm }}
          </VBtn>
        </VRow>
      </VContainer>
    </VCard>
  </VDialog>
</template>

<script>
import RoundButton from '@/components/RoundButton.vue';

export default {
  components: { RoundButton },
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
    },
    id: {
      type: Number,
    },
    textMain: {
      type: String,
      default: 'Данное действие необратимо и приведёт к полному удалению. Вы уверены?',

    },
    textConfirm: {
      type: String,
      default: 'Удалить',
    },
    textCancel: {
      type: String,
      default: 'Отменить',
    },
  },
  emits: ['close', 'delete'],
  methods: {
    close() {
      this.$emit('close');
    },
    remove() {
      this.$emit('delete', this.id);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../assets/scss/menu";

.label-text {
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #000000;
}
.label-title {
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  color: #1E262D;
}
::v-deep{
  .v-btn {
    width: 153px!important;
    height: 40px!important;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
    border-radius: 50px;
  }
}
</style>
