<template>
  <div v-if="label != null">
    <VDivider />
    <div class="d-flex justify-start mt-2 pl-0">
      <VCol class="pl-0" cols="5">
        <p class="text-h6 my-2">
          Создание метки
        </p>
        <input
          v-model="label.title"
          placeholder="Введите название метки"
          type="text"
        />
      </VCol>
      <VCol cols="2">
        <VBtn
          v-if="item.id != null"
          class="align-self-center default-btn white--text ml-4 mb-2"
          color="red"
          rounded
          @click="remove"
        >
          Удалить метку
        </VBtn>
      </VCol>
    </div>
    <div class="d-flex justify-start">
      <VCol class="pl-0" cols="2">
        <VSubheader>Ключевые слова</VSubheader>
      </VCol>
      <VCol cols="3">
        <FoodLabelsInput
          :keywords="label.keywords"
          @keyup="label.keywords = $event"
        />
        <p class="input-alert">
          Если в категории меню ресторана,
          в котором находится это блюдо ИЛИ
          в названии блюда встречается это слово - метка присваивается блюду
        </p>
      </VCol>
    </div>
    <div class="d-flex justify-start">
      <VCol class="pl-0" cols="2">
        <VSubheader>Ключевые АНТИ-слова</VSubheader>
      </VCol>
      <VCol cols="3">
        <FoodLabelsInput
          :keywords="label.keywordsAnti"
          @keyup="label.keywordsAnti = $event"
        />
        <p class="input-alert">
          Если в категории меню ресторана,
          в котором находится это блюдо ИЛИ в названии
          блюда встречается это слово - метка НЕ присваивается блюду
        </p>
      </VCol>
    </div>
    <div v-if="label.coeffRest != null" class="d-flex">
      <VCol class="pl-0" cols="2">
        <VSubheader>Кол-во ресторанов</VSubheader>
      </VCol>
      <VCol cols="2" justify="start">
        <p class="coef">
          {{ Math.round(item.coeffRest * 100) / 100 }} %
        </p>
      </VCol>
    </div>
    <div v-if="label.coeffFood != null" class="d-flex">
      <VCol class="pl-0" cols="2">
        <VSubheader>Кол-во блюд</VSubheader>
      </VCol>
      <VCol cols="2" justify="start">
        <p class="coef">
          {{ Math.round(item.coeffFood * 100) / 100 }} %
        </p>
      </VCol>
    </div>
    <div class="d-flex justify-center mb-6">
      <VBtn
        class="align-self-start default-btn black--text"
        color="#9B9B9B"
        rounded
        @click="cancel"
      >
        Отменить
      </VBtn>
      <VBtn
        class="align-self-center default-btn white--text ml-4"
        color="#4caf50"
        rounded
        @click="save"
      >
        Сохранить
      </VBtn>
    </div>
    <VDivider class="mb-6" />
  </div>
</template>

<script>
import FoodLabelsInput from '@/components/FoodLabelsInput.vue';

export default {
  name: 'FoodLabelsEdit',
  components: {
    FoodLabelsInput,
  },
  props: {
    item: Object,
  },
  emits: ['cancel', 'create', 'edit', 'delete'],
  data() {
    return {
      title: '',
      label: null,
    };
  },
  mounted() {
    this.label = JSON.parse(JSON.stringify(this.item));
  },
  methods: {
    cancel() {
      this.$emit('cancel');
    },
    save() {
      const id = this.label.id || null;
      if (id === null) {
        this.$emit('create', this.label);
      } else {
        this.$emit('edit', this.label);
      }
    },
    remove() {
      this.$emit('delete', this.label);
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'src/assets/scss/page.scss';

.title-view {
  max-width: 30%;
}
.condition {
  margin-right: 10px;
}
.condition-title {
  color: $default_dark_gray_color;
  font-family: $default_bold_font;
  font-size: 15px
}
.input-alert {
  font-weight: 400;
  font-size: 10px;
  line-height: 13px;
  color: #9B9B9B;
  margin-top: 4px;
}
.v-subheader {
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #000000;
}
.coef {
  font-weight: 600;
  font-size: 13px;
  line-height: 16px;
  color: #000000;
  margin-top: 12px;
}
::v-deep {
  .v-text-field {
    border: 2px solid #C7CACE;
    border-radius: 3px;
    height: 40px!important;
    outline: none;
  }
}
input {
  padding-left: 15px;
  background: #F2F2F2;
  border: 1px solid rgb(167, 165, 165);
  border-radius: 4px;
  font-size: 14px;
  line-height: 34px;
  width: 100%;
  &:focus {
    outline: none;
  }
}
::placeholder {
  color: #a7a5a5;
}
</style>
