const buildHeader = (text, value, width, sortable = false, sortValue,
  align, classCallback, component, description, clickable = false) => (
  {
    text,
    value,
    width,
    sortable,
    sortValue,
    align,
    class: classCallback || (''),
    component,
    description,
    clickable,
  }
);

export const restaurantPhoneRows = [
  {
    text: 'Имя',
    classList: 'text-left',
  },
  {
    text: 'Номер',
    classList: 'text-center',
  },
  {
    text: 'По умолчанию',
    classList: 'text-center',
  },
  {
    text: 'Действие',
    classList: 'text-center',
  },
];

export const restaurantIVRPhoneRows = [
  {
    text: 'Имя',
    classList: 'text-left',
  },
  {
    text: 'Номер',
    classList: 'text-center',
  },
  {
    text: 'Действие',
    classList: 'text-center',
  },
];

export const restaurantContractRows = [
  {
    text: 'ID',
    classList: 'pl-3',
  },
  {
    text: 'Договор',
    classList: '',
  },
  {
    text: 'Процент',
    classList: 'text-center',
  },
  {
    text: 'Дата',
    classList: 'text-center',
  },
  {
    text: 'Действие',
    classList: 'text-center',
  },
];

export const contractRestaurantRows = [
  {
    text: 'Заведение',
    classList: 'text-left',
  },
  {
    text: 'Менеджер',
    classList: 'text-center',
  },
  {
    text: 'Договор по умолчанию',
    classList: 'text-center',
  },
  {
    text: 'Действие',
    classList: 'text-center',
  },
];

export const contractRestaurantsCommissions = [
  {
    text: 'Заведение',
    classList: 'pl-4',
  },
  {
    text: 'Менеджер',
  },
  {
    text: 'Процент',
    classList: 'text-center',
  },
  {
    text: 'Дата',
    classList: 'text-center',
  },
  {
    text: 'Действие',
    classList: 'text-center',
  },
];

export const contractContactRows = [
  {
    text: 'Имя',
    classList: 'pl-3',
  },
  {
    text: 'Номер телефона',
    classList: 'pl-3',
  },
  {
    text: 'E-mail',
    classList: 'text-center',
  },
  {
    text: 'Должность',
    classList: 'text-center job-title',
  },
  {
    text: 'Действие',
    classList: 'text-center action',
  },
];

export const contractEventLog = [
  {
    text: 'Пользователь',
    classList: 'pl-3',
  },
  {
    text: 'Категория',
  },
  {
    text: 'Дата',
    classList: 'text-center',
  },
  {
    text: 'Действие',
    classList: 'text-center',
  },
];

export const increasedTariffs = [
  {
    text: 'Название',
    classList: 'text-center',
    styles: {
      width: '100px',
      paddingRight: 0,
    },
  },
  {
    text: 'Повышение доставки',
    classList: 'text-center',
    styles: {
      width: '50px',
      padding: 0,
    },
  },
  {
    text: 'Мин.доставка',
    classList: 'text-center',
    styles: {
      width: '50px',
      padding: 0,
    },
  },
  {
    text: 'Макс.доставка',
    classList: 'text-center',
    styles: {
      width: '50px',
      padding: 0,
    },
  },
  {
    text: 'Повышение мин.суммы доставки',
    classList: 'text-center',
    styles: {
      width: '50px',
      padding: 0,
    },
  },
  {
    text: 'Мин.заказ',
    classList: 'text-center',
    styles: {
      width: '50px',
      padding: 0,
    },
  },
  {
    text: 'Макс.заказ',
    classList: 'text-center',
    styles: {
      width: '50px',
      padding: 0,
    },
  },
  {
    text: 'Ставка курьера',
    classList: 'text-center',
    styles: {
      width: '50px',
      padding: 0,
    },
  },
  {
    text: 'Состояние',
    classList: 'text-center',
    styles: {
      width: '50px',
      paddingLeft: 0,
    },
  },
];

export const FoodLabelsHeader = [
  {
    text: 'Название',
    classList: 'text-start',
  },
  {
    text: 'Ключевые слова',
    classList: 'text-start',
  },
  {
    text: 'Ключевые АНТИ-слова',
    classList: 'text-start',
  },
  {
    text: 'Кол-во ресторанов (%)',
    classList: 'text-center',
  },
  {
    text: 'Кол-во блюд (%)',
    classList: 'text-center',
  },
  {
    text: '',
    classList: 'text-center',
    styles: {
      width: '13%',
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
  {
    text: '',
    classList: 'text-center',
    styles: {
      width: '3%',
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
];

export const storiesHeaders = (totalCities = 0, totalHubs = 0) => [
  buildHeader('id', 'id', '6%'),
  buildHeader('Превью', 'cover', '5.7%', false, null, 'start', '', {
    key: 'Cover',
  }),
  buildHeader('Кол', 'slideCount', '4.6%', true, 'slideCount', 'start', '', {
    key: 'IconButton',
  }),
  buildHeader('Название', 'title', '13.4%', true, 'title', 'start', '', {
    key: 'TableTitle',
    props: {
      'text-wrap': true,
    },
  }, '', true),
  buildHeader('Дата создания', 'createdText', '11%', true, 'created', 'start'),
  buildHeader('Дата начала и окончания', 'dateRangeText', '12%', true, 'dateStart', 'start', 'pr-12'),
  buildHeader('Регионы', 'cities', '12.5%', false, null, 'start', '', {
    key: 'ChipGroup',
    props: {
      max: 1,
      outlined: true,
      'total-items': totalCities,
      'is-total-title': 'Все города',
    },
  }),
  buildHeader('Хабы', 'hubs', '12.4%', false, null, 'start', '', {
    key: 'ChipGroup',
    props: {
      max: 1,
      outlined: true,
      'total-items': totalHubs,
      'is-total-title': 'Все хабы',
    },
  }),
  buildHeader('Позиция', 'position', '3.1%', true, 'position', 'center'),
  buildHeader('Статус', 'state', '10%', false, null, 'end', 'pr-4', {
    key: 'State',
    props: {
      disabled: true,
      small: true,
      height: '32',
    },
  }),
  buildHeader('Автоткрытие', 'isFullScreen', '5%', false, null, 'center', '', {
    key: 'FullScreenToggle',
  }),
  buildHeader('', 'details', '4.3%', false, null, 'center', '', {
    key: 'TableActions',
  }),
];

export const suggestionsHeaders = (totalHubs = 0) => [
  buildHeader('id', 'id', '6.4%'),
  buildHeader('Название', 'title', '', true, 'title', 'start', '', {
    key: 'TableTitle',
  }, '', true),
  buildHeader('Хабы', 'hubs', '14%', false, null, 'start', '', {
    key: 'ChipGroup',
    props: {
      max: 1,
      outlined: true,
      'total-items': totalHubs,
      'is-total-title': 'Все хабы',
    },
  },
  'Тут регионы в которых работает этот Upsell'),
  buildHeader('Дата создания', 'createdAtText', '12%', true, 'created_at'),
  buildHeader('Дата кампании', 'dateRangeText', '20%', true, 'expiration_date'),
  buildHeader('Позиция', 'positionText', '7.1%', true, 'position', 'start'),
  buildHeader('Статус', 'state', '10.1%', false, null, 'end', 'pr-4', {
    key: 'State',
    props: {
      disabled: true,
      small: true,
      height: '32',
    },
  }),
  buildHeader('', 'details', '4.3%', false, null, 'center', '', {
    key: 'TableActions',
  }),
];

export const synonymsRequestHeader = [
  {
    text: 'Текст поиска',
    classList: 'text-start',
  },
  {
    text: 'Тип',
    classList: 'text-start',
  },
  {
    text: 'Синоним',
    classList: 'text-start',
  },
  {
    text: '',
    classList: 'text-center',
    styles: {
      width: '13%',
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
];

export const partnerHeaders = () => [
  buildHeader('ID партнера', 'id', '10%'),
  buildHeader('Название партнера', 'title', '50%', true, 'title', 'start', '', {
    key: 'TableTitle',
    props: {
      'text-wrap': true,
    },
  }, '', true),
  buildHeader('Логин', 'login', '30%', true, 'position', 'start'),
  buildHeader('Активность', 'state', '9%', false, null, 'end', 'pr-4', {
    key: 'State',
    props: {
      disabled: true,
      small: true,
      height: '32',
    },
  }),
];

export const partnerRestaurantsHeader = [
  {
    text: 'Hermes ID',
    classList: 'text-start',
  },
  {
    text: 'Название ресторана в Hermes',
    classList: 'text-start',
  },
  {
    text: 'Комментарий к ресторану',
    classList: 'text-start',
  },
  {
    text: 'ID в системе партнера',
    classList: 'text-start',
  },
  {
    text: '',
    classList: 'text-center',
    styles: {
      width: '5%',
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
];

export const notificationsHeaders = (totalCities = 0, totalHubs = 0) => [
  buildHeader('id', 'id', '7.3%'),
  buildHeader('Название', 'title', '21.8%', false, 'title', 'start', '', {
    key: 'TableTitle',
  }, '', true),
  buildHeader('Платформы', 'targetsToString', '21.8%'),
  buildHeader('Регионы', 'cities', '14.5%', false, null, 'start', '', {
    key: 'ChipGroup',
    props: {
      max: 1,
      outlined: true,
      'total-items': totalCities,
      'is-total-title': 'Все регионы',
    },
  }),
  buildHeader('Хабы', 'hubs', '14.5%', false, null, 'start', '', {
    key: 'ChipGroup',
    props: {
      max: 1,
      outlined: true,
      'total-items': totalHubs,
      'is-total-title': 'Все хабы',
    },
  }),
  buildHeader('Статус', 'state', '12.7%', false, null, '', '', {
    key: 'State',
    props: {
      disabled: true,
      small: true,
      height: '32',
    },
  }),
  buildHeader('', '', '3.4%'),
  buildHeader('', 'details', '4%', false, null, 'center', '', {
    key: 'TableActions',
    props: {
      deletable: false,
    },
  }),
];

export const collectionHeaders = (totalCities = 0) => [
  buildHeader('id', 'id', '7.3%'),
  buildHeader('Название', 'title', '21.8%', false, 'title', 'start', '', {
    key: 'TableTitle',
  }, '', true),
  buildHeader('Тип коллекции', 'typeOfTitle', '10.6%'),
  buildHeader('Контент', 'contents', '18.5%', false, null, 'start', '', {
    key: 'ChipGroup',
    props: {
      max: 1,
      outlined: true,
    },
  }),
  buildHeader('Платформы', 'platformToString', '12.7%'),
  buildHeader('Регионы', 'cities', '14.5%', false, null, 'start', '', {
    key: 'ChipGroup',
    props: {
      max: 1,
      outlined: true,
      'total-items': totalCities,
      'is-total-title': 'Все регионы',
    },
  }),
  buildHeader('Статус', 'state', '9.1%', false, null, 'start', '', {
    key: 'State',
    props: {
      disabled: true,
      small: true,
      height: '32',
    },
  }),
  buildHeader('', 'details', '5.5%', false, null, 'center', '', {
    key: 'TableActions',
    props: {
      deactivatable: false,
    },
  }),
];

export const incomePartnersHeaders = () => [
  buildHeader('Город', 'cities', '15%', false, null, '', 'pl-3', {
    key: 'ChipGroup',
    props: {
      max: 1,
      outlined: true,
    },
  }),
  buildHeader('Информация о заявке', 'partnerDataCombined', '22%', false, null, '', '', {
    key: 'VHTMLData',
  }),
  buildHeader('Менеджер', 'manager', '22%'),
  buildHeader('Дата заявки', 'dtCreated', '12%'),
  buildHeader('Статус заявки', 'state', '13%', false, null, '', '', {
    key: 'State',
    props: {
      disabled: true,
      small: true,
      height: '32',
    },
  }),
  buildHeader('Последние изменения', 'dtUpdated', '12%'),
  buildHeader('', 'details', '4%', false, null, 'center', '', {
    key: 'TableStateActions',
  }),
];

export const chipsHeaders = () => [
  buildHeader('ID', 'id', '6%', false, null, '', 'ml-4'),
  buildHeader('Название чипс Рус', 'ruTitle', '22%', false, null, '', 'font-weight-bold break-word pr-4'),
  buildHeader('Название чипс Қаз', 'kzTitle', '22%', false, null, '', 'font-weight-bold break-word pr-4'),
  buildHeader('Количество звезд', 'rating', '10%', false, null, '', 'pl-4', {
    key: 'Rating',
  }),
  buildHeader('Тип', 'entity_type_text', '8%'),
  buildHeader('Статус', 'state', '7%'),
  buildHeader('Позиция', 'sortPosition', '8%', false, null, 'center'),
  buildHeader('Платформы', 'targets', '12%', false, null, '', 'ml-4', {
    key: 'TableTargets',
  }),
  buildHeader('', '', '5%', false, null, 'center', '', {
    key: 'TableActions',
    props: {
      deletable: false,
      btnIcon: 'mdi-dots-vertical',
      btnHoverClass: 'grey-bg',
      contentHoverClass: 'red-bg',
    },
  }),
];

export const srIntegrationsHeaders = () => [
  buildHeader('id ресторана', 'partnerRestaurant.hermesId', '13%'),
  buildHeader('Название ресторана', 'partnerRestaurant.hermesName', '18%', false, null, 'start', 'font-weight-bold', null, '', true),
  buildHeader('API Login', 'iikoApiLogin', '17%', false, null, 'start', 'font-weight-bold'),
  buildHeader('Название организации', 'iikoOrganization.name', '19%'),
  buildHeader('ID организации', 'iikoOrganization.id', '28%'),
  buildHeader('', '', '5%', false, null, 'center', '', {
    key: 'TableActions',
    props: {
      deactivatable: false,
      btnHoverClass: 'grey-bg',
      contentHoverClass: 'red-bg',
    },
  }),
];
