<template>
  <VCard class="my-5 mx-8" flat>
    <VRow class="d-flex mt-2">
      <VCol class="d-flex" cols="6">
        <VSubheader class="mr-4 mb-0">
          Метки блюд
        </VSubheader>
        <VBtn
          class="default-btn white--text"
          color="#4caf50"
          rounded
          @click="create"
        >
          <VIcon left>
            mdi-plus-circle
          </VIcon>
          Создать метку
        </VBtn>
      </VCol>
      <VCol class="d-flex justify-end" cols="6">
        <VBtn
          class="default-btn white--text"
          color="#4caf50"
          rounded
          @click="updateTagAll"
        >
          Присвоить все метки
        </VBtn>
      </VCol>
    </VRow>
    <VRow class="pl-3 mb-3">
      <div class="input">
        <p class="input-title my-2">
          Поиск
        </p>
        <VTextField
          v-model="params.title "
          background-color="transparent"
          clearable
          color="#8c8c8c"
          dense
          hide-details
          outlined
          placeholder="Поиск по метке"
          prepend-inner-icon="mdi-magnify"
          @change="searchBy()"
          @click:clear="searchBy()"
        />
      </div>
    </VRow>
    <Transition
      duration="550"
      name="nested"
    >
      <FoodLabelsEdit
        v-if="editItem"
        :item="item"
        @cancel="close()"
        @create="createLabel"
        @delete="openDeleteLabel"
        @edit="editLabel"
      />
    </Transition>
    <FoodLabelsTable
      :items="items"
      @delete="openDeleteLabel"
      @edit="openEditLabel"
      @update="updateTag"
    />
    <PrevNextButtons
      :current-page="currentPage"
      :pages-count="totalPagesCount"
      @next-page="changePage(1)"
      @previous-page="changePage(-1)"
    />
    <DeleteDialog
      v-if="item"
      :id="item.id"
      :dialog="dialog"
      :title="item.title"
      @close="dialog = false"
      @delete="deleteLabel"
    />
  </VCard>
</template>

<script>
import FoodLabelsTable from '@/components/FoodLabelsTable.vue';
import FoodLabelsEdit from '@/components/FoodLabelsEdit.vue';
import PrevNextButtons from '@/components/reusable/PrevNextButtons.vue';
import {
  fetchFoodLabels,
  createFoodLabel,
  updateFoodLabel,
  deleteFoodLabel,
  synchronizeFoodLabelById,
  synchronizeFoodLabels,
} from '@/api/api';
import DeleteDialog from '@/components/DeleteDialog.vue';

export default {
  data() {
    return {
      items: [],
      item: null,
      params: {
        limit: 20,
        offset: 0,
        title: '',
      },
      currentPage: 1,
      totalPagesCount: 1,
      dialog: false,
      editItem: false,
    };
  },
  components: {
    FoodLabelsTable,
    FoodLabelsEdit,
    PrevNextButtons,
    DeleteDialog,
  },
  mounted() {
    this.fetchFoodLabelsList();
  },
  methods: {
    fetchFoodLabelsList() {
      fetchFoodLabels(this.params).then((response) => {
        this.items = response.data;
        this.totalPagesCount = response.total_page;
      });
    },
    create() {
      if (this.item == null) {
        this.item = {
          title: '',
          keywords: [],
          keywordsAnti: [],
          coeffRest: null,
          coeffFood: null,
        };
        this.editItem = true;
      }
    },
    close() {
      this.item = null;
      this.editItem = false;
    },
    openEditLabel(item) {
      if (this.item === null) {
        this.item = item;
        this.editItem = true;
      }
    },
    openDeleteLabel(item) {
      this.item = item;
      this.dialog = true;
    },
    createLabel(item) {
      createFoodLabel(item).then(() => {
        this.close();
        this.fetchFoodLabelsList();
      });
    },
    deleteLabel(id) {
      this.dialog = false;
      deleteFoodLabel(id).then(() => {
        this.close();
        this.fetchFoodLabelsList();
      });
    },
    editLabel(item) {
      updateFoodLabel(item.id, item).then(() => {
        this.close();
        this.fetchFoodLabelsList();
      });
    },
    changePage(incOrDecr) {
      this.currentPage += incOrDecr;
      this.params.offset += incOrDecr > 0 ? this.params.limit : -this.params.limit;
      this.fetchFoodLabelsList();
    },
    async updateTag(item) {
      await this.updateLoadingStage(item, true);
      await synchronizeFoodLabelById(item.id)
        .then(() => {
          this.fetchFoodLabelsList();
        })
        .catch(() => {
          this.updateLoadingStage(item, false);
        });
    },
    async updateTagAll() {
      await this.updateLoadingStage(null, true);
      await synchronizeFoodLabels()
        .then(() => {
          this.fetchFoodLabelsList();
        })
        .catch(() => {
          this.updateLoadingStage(null, false);
        });
    },
    updateLoadingStage(item, state) {
      if (item != null) {
        this.items.forEach((element, index) => {
          if (element.id === item.id) {
            this.items[index].loading = state;
          }
        });
      } else {
        this.items.forEach((element, index) => {
          this.items[index].loading = state;
        });
      }
    },
    searchBy() {
      this.fetchFoodLabelsList();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "src/assets/scss/page.scss";
@import "src/assets/scss/menu.scss";

*{
  scroll-behavior: smooth;
}
.v-subheader {
  font-weight: 550;
  font-size: 30px;
  line-height: 38px;
  color: #1F1F1F;
  margin-left: 0px;
  padding-left: 0px;
}
::v-deep{
  .col-12{
    padding-left: 0;
    padding-right: 0;
  }
}
.nested-enter-active, .nested-leave-active {
  transition: all 0.3s ease-in-out;
}
.nested-leave-active {
  transition-delay: 0.25s;
}
.nested-enter-from,
.nested-leave-to {
  transform: translateY(30px);
  opacity: 0;
}
.nested-enter-active .inner,
.nested-leave-active .inner {
  transition: all 0.3s ease-in-out;
}
.nested-enter-active .inner {
  transition-delay: 0.25s;
}

.nested-enter-from .inner,
.nested-leave-to .inner {
  transform: translateX(30px);
  opacity: 0.001;
}
</style>
