<template>
  <div class="chip-container">
    <div
      v-for="(chip, i) of chips"
      :key="chip.label"
      class="chip"
    >
      {{ chip }}
      <VIcon
        color="#E43A35"
        small
        @click="deleteChip(i)"
      >
        mdi-close
      </VIcon>
    </div>
    <input
      v-model="currentInput"
      @keydown.delete="backspaceDelete"
      @keydown.tab.prevent="saveChip"
      @keypress.enter="saveChip"
      @keyup.space="saveChip"
    >
  </div>
</template>

<script>

export default {
  props: {
    set: {
      type: Boolean,
      default: true,
    },
    keywords: {
      type: Array,
      // eslint-disable-next-line vue/require-valid-default-prop
      default: [],
    },
  },
  emits: ['keyup'],
  data() {
    return {
      chips: [],
      currentInput: '',
    };
  },
  mounted() {
    this.chips = this.keywords;
  },
  methods: {
    saveChip() {
      const { chips, currentInput, set } = this;
      // eslint-disable-next-line no-unused-expressions
      ((set && chips.indexOf(currentInput) === -1) || !set) && chips.push(currentInput.replace(' ', ''));
      this.currentInput = '';
      this.$emit('keyup', chips);
    },
    deleteChip(index) {
      this.chips.splice(index, 1);
    },
    backspaceDelete({ which }) {
      // eslint-disable-next-line no-unused-expressions
      which === 8 && this.currentInput === '' && this.chips.splice(this.chips.length - 1);
    },
  },
};
</script>

<style lang="scss" scoped>
.chip-container {
  width: 400px;
  border: 1px solid rgb(167, 165, 165);
  border-radius: 4px;
  min-height: 40px;
  display:flex;
  flex-wrap: wrap;
  align-content: space-between;
  .chip {
    margin: 5px 3px;
    background: #F2F2F2;
    padding: 0 4px;
    border-radius: 4px;
    display: flex;
    align-items: center;
    .v-icon {
      cursor: pointer;
      opacity: .56;
      margin-left:8px;
    }
  }
  input {
    flex: 1 1 auto;
    width: 30px;
    outline: none;
    padding-left: 10px;
    line-height: 37px;
  }
}
</style>
