<template>
  <div class="paginationButtons">
    <VBtn
      class="ma-2"
      color="success"
      :disabled="currentPage <= 1"
      tile
      @click="$emit('previous-page')"
    >
      <VIcon left>
        mdi-chevron-left
      </VIcon> Предыдущие
    </VBtn>
    <VBtn
      class="ma-2"
      color="success"
      :disabled="currentPage >= pagesCount"
      tile
      @click="$emit('next-page')"
    >
      Следующие <VIcon right>
        mdi-chevron-right
      </VIcon>
    </VBtn>
  </div>
</template>

<script>
export default {
  name: 'PrevNextButtons',
  props: {
    pagesCount: {
      type: [Number],
      default: 1,
    },
    currentPage: {
      type: Number,
      default: 1,
    },
  },
  emits: ['previous-page', 'next-page'],
};
</script>

<style lang="scss" scoped>
.paginationButtons {
  display: flex;
  margin-top: 10px;
  justify-content: flex-end;
}
</style>
